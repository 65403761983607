import React, { MouseEventHandler } from "react";
import ResellerTools from "../../images/reseller-tools.png";
import Logo from "../../images/logo";

import { ChatBubbleLeftRightIcon } from "@heroicons/react/24/solid";

type Props = {
  onDemoClick: MouseEventHandler<HTMLButtonElement>;
};

const PartnerSection: React.FC<Props> = ({ onDemoClick }) => {
  return (
    <div className="relative overflow-hidden bg-slate-900 pt-16 pb-48">
      <div className="relative">
        <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-8 lg:gap-6 lg:px-8">
          <div className="col-span-4 flex-grow px-4 sm:px-6 lg:mx-0 lg:px-0 lg:py-24">
            <div className="h-36 w-36">
              <Logo fill={"#059669"} />
            </div>

            <h1 className="text-2xl tracking-tight text-gray-100 sm:mt-5 lg:mt-6 lg:text-4xl">
              <span className="block font-extrabold">
                E-Mobility Engineering at
              </span>
              <span className="block text-4xl font-bold text-primary-600 lg:text-8xl">
                Internet Scale
              </span>
            </h1>
            <p className="mt-4 text-lg text-gray-200">
              Companies of all sizes trust Green Action Studio's expertise to
              build, scale, and manage their e-mobility software and APIs.
            </p>
            <div className="mt-6">
              <button
                onClick={onDemoClick}
                className="inline-flex items-center rounded-md bg-primary-600 py-3 px-4 font-medium text-white shadow transition-all duration-300 hover:bg-primary-700 focus:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-primary-600 focus:ring-offset-2 focus:ring-offset-gray-900"
              >
                <ChatBubbleLeftRightIcon
                  className="mr-2 hidden h-5 lg:block"
                  aria-hidden="true"
                />
                Speak with our E-Mobility Engineers
              </button>
            </div>
          </div>
          <div className="col-span-1 col-start-6 mt-12 sm:mt-16 lg:mt-0">
            <div className="-mr-48 pl-4 sm:pl-6 md:-mr-16 lg:relative lg:m-0 lg:h-full lg:px-0">
              <img
                className="w-full rounded-xl shadow-xl lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                src={ResellerTools}
                alt="EV Management Dashboard"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerSection;
