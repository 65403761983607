import React from "react";
import { Helmet } from "react-helmet";

import Footer from "../components/common/Footer";
import FeatureGrid from "../components/marketing/FeatureGrid";
import HomepageHero from "../components/marketing/HomepageHero";
import { openPopupWidget } from "react-calendly";
import { ArrowRightIcon } from "@heroicons/react/24/solid";

export default function Index() {
  const onTechClick = () =>
    openPopupWidget({
      url: "https://calendly.com/green-action-studio/introduction-call",
    });

  return (
    <div className="relative overflow-hidden">
      <Helmet>
        <meta charSet="utf-8" />
        <title>E-Mobility Engineering | Green Action Studio</title>
        <meta
          name="description"
          content="We make managing EV
            charging easy. Whether you have a single plug or a nationwide
            charging network, we provide the tools you need to get things done."
        ></meta>
      </Helmet>
      <main>
        <div className="fixed bottom-2 z-50 p-3">
          <div className="inline-flex flex-row items-center justify-between rounded-full bg-indigo-600 py-1 pl-6 pr-1">
            <span className="pr-8 text-sm font-bold text-gray-100 antialiased">
              We're accepting new interns for our internship program!
            </span>
            <a
              className="flex flex-none flex-row items-center rounded-full bg-indigo-800 py-2 px-3 transition-colors hover:cursor-pointer hover:bg-indigo-900"
              href="/careers/software-internship"
            >
              <span className="hidden text-sm font-bold text-white antialiased md:block">
                Learn More
              </span>
              <ArrowRightIcon className="h-4 text-white md:hidden" />
            </a>
          </div>
        </div>
        <HomepageHero onDemoClick={onTechClick} />
        {/* Partner Solutions */}
        <div className="relative z-0 -mt-10 -mr-10 h-32 -rotate-2 transform bg-white lg:-mt-20"></div>
        <div className="relative z-10 -mt-20">
          <FeatureGrid />
        </div>
      </main>
      <Footer />
    </div>
  );
}
