import React from "react";
import {
  ArrowUpIcon,
  CogIcon,
  LockClosedIcon,
  ServerIcon,
  ShieldCheckIcon,
  CloudIcon,
} from "@heroicons/react/24/outline";

const features = [
  {
    name: "Cloud Native Applications",
    description:
      "Our team is an expert in deploying and building scalable applications to the cloud.",
    icon: CloudIcon,
  },
  {
    name: "API First Developement",
    description:
      "We know it's tough to predict the future, so we build with extensibility in mind.",
    icon: CogIcon,
  },
  {
    name: "Advanced Security",
    description:
      "We lock things down from the start, so you never have to worry about cyber attacks.",
    icon: ShieldCheckIcon,
  },
];

export default function FeatureGrid() {
  return (
    <div className="relative bg-white py-24 sm:py-32 lg:py-40">
      <div className="mx-auto max-w-md px-6 text-center sm:max-w-3xl lg:max-w-7xl lg:px-8">
        <h2 className="text-3xl font-semibold text-slate-800 sm:text-4xl">
          Everything you need to deploy your E-Mobility Experience
        </h2>
        <p className="mx-auto mt-5 max-w-prose text-xl text-gray-500">
          Go to market in record time by saving over 1,000 development hours
          with our pre-built system. We have already handled the heavy lifting,
          which frees your team to remain laser-focused on your core business
          logic.
        </p>
        <div className="mt-20">
          <div className="grid grid-cols-1 gap-12 sm:grid-cols-2 lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="pt-6">
                <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8">
                  <div className="-mt-6">
                    <div>
                      <span className="inline-flex items-center justify-center rounded-xl bg-primary-600 p-3 shadow-lg">
                        <feature.icon
                          className="h-8 w-8 text-white"
                          aria-hidden="true"
                        />
                      </span>
                    </div>
                    <h3 className="mt-8 text-lg font-semibold leading-8 tracking-tight text-gray-900">
                      {feature.name}
                    </h3>
                    <p className="mt-5 text-base leading-7 text-gray-600">
                      {feature.description}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
